import FeedbackLink from 'src/components/feedback/FeedbackLink';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import moment from 'moment';
import React from 'react';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import styles from 'src/stylesheets/feedback.scss';
import WarningContent from 'src/components/feedback/warnings/WarningContent';

const NotFullyCovered = (props) => {
    const { warningData, accountOptions } = props;
    const content = (
        <ul className={styles.list}>
            {
                warningData.profiles.map((profile) => (
                    <li key={profile.id}>
                        {profile.name} ({moment(profile.createTime.date).format(accountOptions.dateFormat)})
                    </li>
                ))
            }
        </ul>
    );

    return (
        <WarningContent
          headline="Audience data availability"
          message={<>We begin tracking <FeedbackLink target="_blank" to="https://help.facelift-cloud.com/en/articles/8624821-data-availability-of-networks-on-private-and-public-level" type="warning">{'\''}Audience Data{'\''}</FeedbackLink> the day a profile is added to quintly therefore there is no {'\''}Audience Data{'\''} available before the added date. For the following profiles, audience data is available from:</>}
          content={content}
        />
    );
};

NotFullyCovered.propTypes = {
    warningData: customPropTypes.notFullyCoveredWarning.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(NotFullyCovered);
